import React, { Component } from "react";
import ReactReadLessMore from "react-read-less-more";

// import { FaAngleDoubleDown } from 'react-icons/fa';
// import { FaAngleDoubleUp } from 'react-icons/fa';
import homeData from "../data/homeData";
//import Container from '../components/Container';
//import Image from '../components/Image.js';
//import imageData from '../data/imageData';
//import Client from '../contentful';
import img1 from "../images/MADJARSKI  SNIMKA.jpg";
import img2 from "../images/photo001.jpg";
import img3 from "../images/photo002.jpg";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      visible: true,
      data: [],
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  // getData = async () => {
  //     try {
  //         Client.getEntries({
  //             content_type: "cosmosApp",
  //             order: 'sys.createdAt'
  //         }).then((response) => {
  //             this.setState({
  //                 data: response.items
  //             })
  //         }
  //         )
  //     }
  //     catch (error) {
  //         console.log(error);
  //     }
  // }

  componentDidMount() {
    // this.getData();
    this.setState({
      data: homeData,
    });
  }

  handleToggle() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="border">
          <ReactReadLessMore
            lessText="Скрий"
            moreText="Покажи"
            displayCount={2}
          >
            <span>
              <h1>ЕДИНАДЕСЕТИ НАЦИОНАЛЕН ПОЕТИЧЕН КОНКУРС </h1>
            </span>
            <span>
              <h2>„Академик НИКОЛАЙ ЛИЛИЕВ“ - 2024 - СТАРА ЗАГОРА РЕЗУЛТАТИ</h2>
            </span>

            <span>
              <pre>{`
ПЪРВА НАГРАДА Награда на Кмета на Община Стара Загора

Лауреат:  Диляна Христова Христова

  „ОСЪЗНАВАНЕ“


ВТОРА НАГРАДА от Фондация „КОСМОС – Д.Брацов“  Стара Загора
Лауреат: Цонка Людмилова Христова   гр. Дряново,
„СЪН НА ЯВЕ“


ТРЕТА НАГРАДА от Народно читалище „Николай Лилиев-2005” Стара Загора

Ирина Сергеева Радионова    гр.  Разград

„ЖИВОТЕ!“


ТРИ ОТЛИЧИЯ по 100 лв.:

От Дружество на писателите Стара Загора

Светлана Йонкова Стоянова Андреева    София

 „ШЕПОТ“
-----------------------------------

От  ЕТ „БИМЕД- Иванка Брацова” - Стара Загора

 Петрана Николаева Петрова   гр. Враца

„ПРОЛЕТТА СЕ ЗАВЪРНА”
------------------------------

От Народно читалище „Даскал Петър Иванов-1988“ – Стара Загора

 Ваня Георгиева Велева           Ямбол
 „СЛЕД ЗИМАТА“





КОНКУРСНО ЖУРИ:

Председател:  Атанас Ангелов Капралов ……………………

Членове:   Йордан Димитров Пеев ………………………

Виолета Колева Бончева …………………….

Йордан Цонев Атанасов ……………………..

Димитър Генов Брацов: ……………………….




НАГРАДЕНИ СТИХОТВОРЕНИЯ


1.	Диляна Христова Христова

ОСЪЗНАВАНЕ

Сякаш времето спъна се подло във моите крачки –
рухнах с цялата тежест на Космоса. Пулсът е тих.
И преди окончателно този живот да ме смачка,
ще му хвърля в лицето последния истински стих.

Този стих ще чернее, досущ като тлъста утайка,
загрозила сърцето на белия скъп порцелан.
И ще бликне в сълза от око на почернена майка,
скрила снимка на мъртвото чедо във мократа длан.

Този стих ще напиша с кръвта на пробитата вена.
Всяка дума ще срича за мен в генетичния код.
Но е тъжно – след мен ще е същата тази Вселена
и досущ ще е същият този напразен живот.

Аз не вярвам в Душата. Тя, моята, вече се пръсна.
Вярвам само в човека, към дупката който върви.
Няма нищо божествено в мен, че и аз да възкръсна.
Само няколко думи написах, а колко кърви…









2.	Цонка Людмилова Христова

СЪН НА ЯВЕ

Очи на спомени надничат в съня ми.
Очи среднощносини метличини.
Виждам мама, която отваря
широко прозорците
в пролетно утро, сладко като крем-карамел.
Усмивката ѝ полита
и каца върху люляка,
а той рони ароматни сълзи
и се обяснява в любов на чучулигата.
Ето го и татко – наперен гларус на плажа в Шабла.
Около него рояк дечица,
наръсени с шоколадови пръчици,
а в очите им се вълнува морето
и подскачат златни рибки.
Мимолетни устни докосват моите,
змиевидни пръсти ме прегръщат.

Докосва гърдите ми малка жива топчица,
уханна като житена питка.
Паля свещичка на баба и дядо
по черешова задушница
и сърцето ми проси милост от смъртта,
но тя окосява поляните на съня ми
и ме връща във утрото,
на което принадлежа...
И разбирам, че нищо от това не е мое -
животът си пише приказки на гърба ми,
а аз съм просто героиня в съновидения.
Но никак не искам да се будя,
за да продължа приказката наяве.
Ала отвън ухае на момини сълзи
и смъртта в ъгъла на времето
ми плете терлици от паяжини.




3.	Ирина Сергеева Радионова

ЖИВОТЕ!

Аз няма да призная, че съм стъпкана,
защото само слабите мърморят.
Но ето – ще ти дам да се разходиш
в тревата под краката на деня ми.
И нека е зелено и уютно
в деня ти с мойте две необозрения –
децата ми, мъжът ми – в стълкновение
със всичко, от което се страхувам.
Защо? Аз вече отговори нямам.
Но псувайки, замесвам днес и ангели.
Мърсувам из молитвени трептения
с оргазмите на хилядите дяволи,
поискали да бъда само тяхна.

Аз – ничия земя – да ме завземеш!
Аз – ничия жена – за обладаване!
Аз – майка нечия – от теб заченала!

Аз! В края на деня –
едно сираче.












ОТЛИЧЕНИ:


Светлана Йонкова (Стоянова Андреева)

ШЕПОТ

… доволно е, че даваш покривката и хляба,
		и ние в тебе, майко, ще умрем чужденци!
 „Отечество любезно“, Иван Вазов

Тук мирише на папрат, къпини и мечки.
Тук животът те гледа с безброй сетива!
В равнината от грижи захлупен, човече,
колко често към стръмното вдигаш глава?
Разговаряш ли с Бог –Той е бор преоблечен,
Той е подбел маскиран, кошута и пън…
Тук извира от всичко космичната вечност,
невидяна от тебе дори и насън.
Закачлива шишарка и звън на мушица
ти се смеят, но ти си безкрайно зает!
Проследи ли с очи отлетялата птица
към зората, разкъсала нощния креп?
Като пръснати гъби равнецът белее,
тишината говори, но чуваш ли ти?
Крачиш гордо, понеже си имаш идеи,
ала нямаш, човече, неземни мечти!
Да си беден сред толкова много богатство…
Но да литне безкрилият би ли могъл?
Завърни се, човече, от своето бягство,
че пръстта ти нашепва защо си дошъл.





Петрана Николаева Петрова

ПРОЛЕТТА СЕ ЗАВЪРНА

Пролетта се завърна. Разпрегна коне и ги пусна.
С топла млада ръка заподрежда небесната шир.
Сякаш пак да открехнеш живота е древно изкуство,
от което се ражда небето и множи се безспир.

Задъхтяха ливади, прибрали коси посивели
и в зелено изплакнали своите жадни очи.
Тишината отстъпи и се сви под априлските трели,
за да може отново звънът на живот да звучи.

Слезе бързо цветът на надеждата в ниското даже –
от билата измръзнали, вперили строги чела.
Никой вече не помни и не може на мен да разкаже
колко дълго е спал под студена завивка света.

Топлината развя своя порив оранжев и трепкащ
и изправи надеждата, влюбена в новия ден.
Пролетта се завърна. И в сърцето си първо я срещам,
за да стигне най-сетне дъха ѝ разбуждащ до мен.







Ваня Георгиева Велева

СЛЕД ЗИМАТА

Над корито, препълнило своя корем с дъждове,
се оглежда луната, в последната седмица трудна.
Тя говори с небето на своя език откровен
и разплаква два облака, дето от вчера са будни.

По реката се влачат вълните, подобно змии,
бързат щъркели, мерят с червени аршини полето.
И покълва коприва, а кос необята пили.
И се пълни с надежда и обич на пролет котлето.

Само вечер, когато земята се сгушва за сън
и прикрита зад хълма луната лъчите си реше,
самотата прегръща на люляк лилавия звън
и се моли щурецът за всеки, дори да е грешен.

И тогава се чува от лунни кристали сглобен
на вселенския разум фалцета, с любовни мелизми.
С мисълта си небесна той влива цвета си във мен
и връвта на тъгата, от старата зима отгризва.


                `}</pre>
            </span>
          </ReactReadLessMore>
        </div>
        <div className="border">
          <ReactReadLessMore
            lessText="Скрий"
            moreText="Покажи"
            displayCount={2}
          >
            <span>
              <h1>ВЕЛИКДЕНСКИ ПОСТИ...</h1>
            </span>
            <span>
              <h2>/Христос ли воскресе.../</h2>
            </span>
            <span>
              <pre>
                <div>
                  Сълза да отрониш за просяка, <br></br>
                  коричка от хляба си ти да дариш <br></br>и вечният глад и
                  болната мъка <br></br>в душата му плаха ти да стопиш...{" "}
                  <br></br>
                  Д. Брацов <br></br>
                  <br></br>
                </div>
                <img src={img2} class="img img-center" alt="Easter one" />
                <p className="italic">
                  Боже мой, в Светлината на празника теб ще помоля:-<br></br>
                  От небето високо тихо да слезеш, <br></br>
                  за малко да спреш пред църковните порти, <br></br>
                  да видиш тук на Земята, дали по Небесната Воля <br></br>
                  до болка, от глад посиняват ръцете на хиляди смъртни.<br></br>
                  Паричката, твоята древна монета не искам за мене. <br></br>
                  Ти за мен си и Бог и свидетел, <br></br>
                  нека тя да остане Спомен за тебе. <br></br>
                  Суха коричка, огризка от великденска постна вечеря <br></br>и
                  капчица вино от тялото божие, <br></br>
                  от Иисуса искам да вкуся..<br></br>
                  <br></br>
                  Сълза да отрониш, мили мой боже, за мене, за Просяка,{" "}
                  <br></br>
                  трохичка от снощният, от постният хляб на мен да дариш{" "}
                  <br></br>и побелелият глад на очите и недоспалата болка{" "}
                  <br></br>
                  душата самотна на просяка, миром, с ръка да приспиш...
                  <br></br> <br></br>
                  Камбаните медни и тази, както и всяка друга година <br></br>в
                  захлас, до забрава, Твоето име пак ще възнасят <br></br>
                  Христос ли воскресе? - Небето смирено ще питат... <br></br>
                  <br></br>
                  Надеждата плаха и свята на гладните смъртни <br></br>
                  по божият свят Птиците в песен пак ще разнасят. <br></br>
                  Мечтата божествена Просяка пак ще погали<br></br>и пак ще
                  разплита <br></br>в сънят му тревожен въпросите вечни:-{" "}
                  <br></br>
                  Воистина ли, Боже, Иисуса Христа в душите ни<br></br>
                  плахи и детски <br></br>
                  за кой ли път и отново, пак ще възкръсне. <br></br>
                  25.04.2013 г.<br></br>
                </p>
              </pre>
              <img src={img3} class="img img-center" alt="Easter two" />

              <h1 className="center">ЗА ПОСЛЕДНО СБОГОМ…</h1>
              <div>
                На 4 януари 2022 г. на 82-годишна възраст, ни напусна Стойчо
                Маджарски.
              </div>
              <div>
                „Брилянтен майстор на поетичното слово, блестящ журналист,
                изящен автор на проза и ценен преводач от руски език, с ярко
                присъствие в старозагорския поетичен небосклон. С многостранна
                дейност и обществени ангажименти, в които влагаше еднаква
                отговорност, принципност и всеотдайност. Безрезервно отдаден на
                каузата, наречена Читалище, той бе председател на НЧ „Съзнание
                1900″-с. Казанка повече от десетилетие, а до края на жизнения си
                път остана съпричастен към неговата дейност! Оставаш в сърцата
                ни, поете! Светъл да е пътят ти!“, се казва още в съобщението от
                официалната Фейсбук страница на отделите „Култура“ и „Туризъм и
                младежки дейности“ към Община Стара Загора..
              </div>
              <div>
                Стойчо Маджарски (Стойчо Митев Стойчев), български поет, прозаик
                и преводач, е роден на 22.02.1939 г. в село Маджерито, община
                Стара Загора. Завършва средното си образование в Стара Загора
                (1957) и българска филология във ВТУ „Св. св. Кирил и Методий” -
                В. Търново (1980). Работи в Коми - АССР като директор на Дом за
                култура, журналист в българския вестник и телевизия (1972-1990).
                Бил е заместник- главен редактор на сп. „Участие” и Завеждащ
                редакция в Радиовъзел - Стара Загора. Има публикувани
                стихотворения във в. „Септември”, „Литературен глас”, в сп.
                „Участие”, „Птици в нощта”, „Кула”, „Съвременност” (Македония) и
                др. Работил е и като главен редактор на в. „Синя поща” и е
                основател на сп. „Птици в нощта”, излизало в Стара Загора
                (1999-2004). Бил е отговорен секретар на в. „Литературен глас”.
                Носител на наградата за литература и изкуство „Николай Лилиев”,
                голямата награда на Съюза на руските писатели през 2008 г. -
                „Златен Есенински медал”, на Петнайстата награда „Золотая муза”
                (2009), на орден „Кирил и Методий” - II степен и още десет
                отличия. Член е на Съюза на свободните писатели. Автор на 23
                книги, 11 от които с поезия - „Вик от тайгата” (стихове, 1989),
                „Смях без мито” (хумор, 1990), „Бяла магия” (1992), „И все
                душата ми е жадна” (1994), „Докато дойде денят” (1996), „Врата”
                (1997), „Звездни бързеи” (1999), проза - „Под сянката на
                студения паралел. Сборник документални разкази” (2001) и др.
                Преводи: „Ще си издигна паметник неръкотворен. Избрана лирика от
                А. С. Пушкин” (1990, „Щом горя, до пепел нека бъде!… Избрани
                стихотворения от С. А. Есенин” (2000), „Тъжните славеи на Русия”
              </div>
              <div>
                (2013) и др. Превежда и от френски език. Живееше в с. Казанка,
                община Стара Загора.
              </div>
              <img src={img1} className="img img-center" alt="Logo" />

              <pre>
                {" "}
                I. КАТАРЗИС <br></br>
                (С дълбока скръб споделям<br></br>
                спомен скъп за Стойчо Маджарски)<br></br>
                Пътуват, топят се бавно снеговете и годините!<br></br>
                По стъпките на Времето бели облаци летят, <br></br>
                отмерват крачки барабаните.<br></br>
                Отиват си след тях – по пътя звезден към могилите<br></br>
                Господата и Другарите, – от Бога най-избраните.<br></br>
                Заспиват Дните, задрямали в чакалните на Гарите<br></br>в
                очакване на пореден безнадежден черен Влак!<br></br>
                Билета скъсан, Божи Стих последен<br></br>в две ръце –
                прощалното Есе<br></br>и недоспала, недопята Песен, <br></br>
                която в душата си ще носим до Безкрая чак!<br></br>
                Потегляме, Приятелю, прегърнали цветя<br></br>
                надежди и розови мечти<br></br>
                за оня Райски, по-добрия Свят по Божите Селения, <br></br>
                дето ще го дирим цяла Вечност<br></br>
                при далечните Звезди, <br></br>а там да бродят в Сънят ни вечен
                космически видения…<br></br>
                От перона празен, на път ще ни изпрати нас утехата, <br></br>
                че на Земята, за другите след нас, ние сме оставили<br></br>
                Божи дар извечен, <br></br>
                стихът ни земен, <br></br>
                люлчината Песен, <br></br>и Палитрата, и четките<br></br>
                от нас на скрито, в късен час<br></br>
                за всички вас пред Райските врати<br></br>в дар някъде оставили…
                <br></br>С последно Сбогом: Димитър Брацов<br></br>
                Фондация „Космос – Д. Брацов“<br></br>
                Династия Брацови<br></br>
                Интелектуален елит на Стара Загора<br></br>4 януари 2022 г.
                <br></br>
                Стара Загора, Резиденция „Змейово“{" "}
              </pre>

              <pre>
                II. НА ПРОЩАВАНЕ<br></br>В памят на Стойчо Маджарски -<br></br>
                Съосновател на Фондация „Космос – ДБ“,<br></br>В тъжен Ден
                изпращам на Небето<br></br>
                най-добрият наш Другар, Колега и Приятел <br></br>и с воплите на
                зимен, декемврийски Вятър <br></br>
                заплакаха всички Божи птици по полето!<br></br>
                Господ-Бог и Той изхлипа в Тишината!<br></br>
                Божите сълзи събира в топли шепи Самотата <br></br>и пак ли, в
                будно Утро ще литне недоспала Чучулига, <br></br>
                Теб да дири високо горе нейде в Синевата!<br></br>
                Нощта отново в Ято Птиците събира!<br></br>
                Сама самичка и Тя ще плаче в Самота.<br></br>И плаках аз на
                тъмно, заедно с Дъжда, <br></br>
                когато Ти си тръгна много тъжен от Света!<br></br>
                Ще те сънуват в огън всички мои Сетива, <br></br>
                теб Единствен и след Теб, - усмивката игрива <br></br>и в
                небесносиньо Утро твоят спомен неспокоен <br></br>
                за кой ли път и отново, пак ще ме събужда и приспива…<br></br>С
                последно Сбогом: Димитър Брацов<br></br>
                Фондация „Космос – Д. Брацов“<br></br>
                Династия Брацови Интелектуален елит на Стара Загора<br></br>4
                януари 2022 г.<br></br>
                Стара Загора, Резиденция „Змейово“{" "}
              </pre>
              <pre>
                III. РЕКВИЕМ<br></br>
                За Стойчо Маджарски -<br></br>
                Основател на списание „Птици в нощта“<br></br>В края на Земният
                ти Път,<br></br>
                видях в този неизбежен тъжен Миг, <br></br>
                Теб Те драсна Господ-Бог завинаги <br></br>
                от Неговият, невидимият Списък!<br></br>
                Ще прати Той Колесница с Огнени коне, <br></br>
                от този грешен Свят, от Земята да те вземе.<br></br>
                Ще го помоля Аз на Колене, -<br></br>
                на Самотният ти Гроб да дойдат<br></br>
                топли, бели, южни Ветрове, -<br></br>
                да те погалят, да си вземат Сбогом с Теб <br></br>и Те, - за Теб
                да викнат Песен за последно…<br></br>
                Зная, на прощаване, не ще поискаш<br></br>
                нито Писък, <br></br>
                нито Вопъл, <br></br>
                нито Вик.<br></br>
                Ще помолиш, отгоре да ти сложат <br></br>
                скромен Божи Кръст <br></br>и да хвърлят после <br></br>
                шепичка за сбогом, <br></br>
                от родната, <br></br>
                от нашенската Пръст!...<br></br>С последно Сбогом: Димитър
                Брацов<br></br>
                Фондация „Космос – Д. Брацов“<br></br>
                Династия Брацови<br></br>
                Интелектуален елит на Стара Загора<br></br>4 януари 2022 г.
                <br></br>
                Стара Загора, Резиденция „Змейово{" "}
              </pre>
            </span>
          </ReactReadLessMore>
        </div>
        <br />
        <div className="border">
          <ReactReadLessMore
            lessText="Скрий"
            moreText="Покажи"
            displayCount={2}
          >
            <span>
              <h1 className="center">НАПРАВЛЕНИЯ ЗА 2022 ГОДИНА</h1>
            </span>
            <span>
              I направление: Поезия, Белетристика, Публицистика <br />
            </span>
            <span>
              <div>
                <br />
                Комисия по номинацията: <br />
                Дружество на писателите – Стара Загора, ръководство: <br />
                1. Таньо Клисуров – председател <br />
                2. Виолета Бончева – член <br />
                3. Йордан Пеев – член <br /> <br />
                Номинирани за 2022 г.: <br />
                1. Стойчо Митев Стойчев (Маджарски) - посмъртно <br />
                1.1. Стойност на номинацията – 1000 лв. <br />
                1.2. Възпоменателна грамота <br />
                1.3. Цел: Издаване на книга – Избрани произведения <br /> <br />
                Примерно заглавие: „Молитва за прошка, молитва за обич“ <br />{" "}
                <br />
                Мото: И за мен спомняйте <br />
                винаги с добро, - <br />
                защото винаги съм бил <br />
                от една сама Звезда – <br />
                отронена сълза. <br /> <br />
                Обявяване: 1 ноември 2022 г. – Стара Загора <br />
                РБ „Захарий Княжески“, Ден на Народните Будители (Апостоли){" "}
                <br /> <br />
                Редакционен колектив: <br />
                1. Таньо Клисуров – Гл. Редактор на книгата <br />
                2. Живка Стойчева – Редактор Логистика <br />
                3. Йордан Пеев – редактор <br />
                4. Виолета Бончева – редактор <br />
                5. Йордан Атанасов – редактор <br />
                6. Десислава Малчева – предпечат, дизайн <br /> <br />
                Задачи: 1) ноември – декември 2022 г. – изготвяне, предпечат +
                издаване на книгата - за Коледа 2022 г. <br />
                2) януари 2023 г. – Възпоменателна презентация на книгата пред
                интелектуалната общност на Стара Загора – РБ „Захарий Княжески“{" "}
                <br /> <br />
                II направление: Изобразително изкуство, Живопис, Хералдика{" "}
                <br /> <br />
                Комисия по номинацията: <br /> <br />
                1. проф. Марин Добрев – съпредседател <br />
                2. Милен Алагенски – съпредседател <br />
                3. Валентин Дончевски - член <br /> <br />
                Номинирани за 2022 г.: <br />
                1. Христо Николов Танев – посмъртно <br />
                1.1. Стойност на номинацията – 1000 лв. <br />
                1.2. Възпоменателна грамота <br />
                1.3. Цел: Издаване на сборник: <br />
                - Публицистика – АЗ-ът на българите <br />
                - Хералдика <br />
                - Живопис <br />
                Каталог на неговите художествени произведения. <br />
                Примерно заглавие: „За вас живях, за вас болях!“ <br /> <br />
                Мото: И бях АЗ – <br />
                от една сама Звезда <br />
                отронена сълза… <br /> <br />
                Обявяване: 1 ноември 2022 г. – Стара Загора <br />
                РБ „Захарий Княжески“, Ден на Народните Будители (Апостоли){" "}
                <br /> <br />
                Редакционен колектив: <br />
                1. Трифон Митев – Гл. Редактор <br />
                2. Николай Христов Танев – логистика, редактор <br />
                3. Огнян Христов Танев – Логистика, редактор <br />
                4. Таньо Клисуров - редактор (по родова линия) <br />
                5. НЧ „Христо Танев – 2020“ – корпоративен член: <br />
                5.1. Емилия Симова – Председател, логистика <br />
                5.2. Васа Русева – Секретар, логистика <br /> <br />
                Задачи: <br />
                1) След 01.11.2022 г. – до 01.03.2023 г. – издаване на сборника{" "}
                <br />
                2) На Първа пролет, 2023 г. – презентация на книгата <br />
                - Възпоменание за него - Христо Танев <br /> <br />
                III направление: <br />
                Обществено-полезна Читалищна (Апостолска) дейност <br /> <br />
                Комисия по номинацията: <br />
                1. Съюз на народните читалища – София <br />
                1.1. – Николай Дойнов – Председател <br />
                2. Община Стара Загора <br />
                2.1. Диана Атанасова <br />
                2.2. Анелия Овчарова <br /> <br />
                Номинирани за 2022 г.: <br />
                1. Иван Аяров – Заслужил деятел – посмъртно <br />
                1.1. Стойност на номинацията – 1000 лв. <br />
                1.2. Възпоменателна грамота <br />
                1.3. Цел: Издаване на сборник творческа биография: <br />
                - поезия, белетристика, публицистика <br />
                - Обществено-полезна (Апостолска) читалищна дейност <br />{" "}
                <br />
                Обявяване: 1 ноември 2022 г. – Стара Загора <br />
                РБ „Захарий Княжески“, Ден на Народните Будители (Апостоли){" "}
                <br />
                Редакционен колектив: <br />
                1. Трифон Митев – Гл. Редактор <br />
                2. НЧ „Св. Климент Охридски“ – логистика <br />
                2.1. Васил Герлимов – председател <br />
                2.2. г-жа Михайлова – секретар <br />
                2.3. Творческият колектив на читалището <br /> <br />
                Задачи: <br />
                1) от 01.11.2022 г. – 20.12.2022 г. редакция + издаване на
                сборника <br />
                2) на 22.12.2022 г.- в киносалона на Читалището – Възпоменателна
                вечер за д-р Иван Аяров + презентация на сборника. <br />
                Съставил: <br />
                Димитър Брацов <br /> <br /> <br />
                ПЕРСПЕКТИВА ЗА 2023 ГОДИНА <br /> <br />
                I направление: <br /> <br />
                Поезия, белетристика, публицистика <br /> <br />
                1. Номинация – Таньо Стоянов Клисуров <br />
                Поет, писател, публицист <br /> <br />
                2. Номинация - Виолета Бончева <br />
                Поет, преводач, испанист <br /> <br />
                3. Номинация: академик Крум Георгиев <br />
                Поет, фолклорист <br /> <br />
                4. Номинация – Йордан Цонев Атанасов <br />
                Поет, писател, публицист <br /> <br />
                5. Номинация – доц. Злати Златев - посмъртно <br />
                Художник <br /> <br />
                В последващи години проектът „Златен фонд“ продължава. <br />{" "}
                <br />
                Президент на Фондацията: <br />
                Димитър Брацов <br />
              </div>
            </span>
          </ReactReadLessMore>
        </div>
        <br />

        <div className="border">
          <ReactReadLessMore
            lessText="Скрий"
            moreText="Покажи"
            displayCount={2}
          >
            <span>
              <h1>Фондация „КОСМОС – Димитър Брацов“ Стара Загора</h1>
            </span>
            <span>
              Предистория: <br />
            </span>
            <span>
              I ПРОЕКТ:
              <br />
              <br />
              Реализиран, продължава и оптимизира позитивно развитие:
              <br />
              <br />
              Фондация „Интелектуален клуб КОСМОС – Димитър Брацов“
              <br />
              За: Литература, Изкуство и Култура
              <br />
              <br />
              Учредяване – 01.06 2013 г., <br />
              Резиденция Змейово, Общ. Ст. Загора
              <br />
              <br />
              1. Учредители:
              <br />
              <br />
              1. Меценати: Фамилия Брацови – Стара Загора
              <br />
              1.1. Димитър Генов Брацов – председател
              <br />
              1.2. Иванка Митева Брацова – президент на
              <br />
              ЕТ „БИМЕД- Иванка Брацова“ – Ст. Загора
              <br />
              1.3. инж. Милена Димитрова Брацова – гр. София
              <br />
              1.4. Георги Николаев Барбудов – гр. София
              <br />
              1.5. Евгений Димитров Брацов – Стара Загора
              <br />
              1.6. Мария Мирославова Брацова – Стара Загора
              <br />
              1.7. Димитър Евгениев Брацов – Стара Загора
              <br />
              1.8. Ивайло Евгениев Брацов – Стара Загора
              <br />
              <br />
              2. Регистрация на Фондацията:
              <br />
              <br />
              2.1. На 27 януари 2014 г. – Окръжен съд – Стара Загора
              <br />
              Регистрирана юридическа структура:
              <br />
              Фондация „Интелектуален клуб КОСМОС – Димитър Брацов“
              <br />
              За: Литература, Изкуство и Култура
              <br />
              <br />
              Седалище – гр. Стара Загора
              <br />
              Кв. Три чучура север бл. 79, ет. 1 ап. 01
              <br />
              <br />
              Президент Димитър Генов Брацов
              <br />
              ЕГН: 4607307786, моб. тел. 0898 679 609
              <br />
              <br />
              Считано от датата на учредяването: 01.06.2013 г.,
              <br /> през датата на ре-гистрацията: 27 януари 2014 г.
              <br /> до днешния ден и за в бъдеще Фонда-цията е била,
              <br /> е и ще бъде законосъобразен партньор на Община Стара
              Загора,
              <br /> Област Стара Загора и Република България, на всички
              дър-жавни, правителствени,
              <br /> общински и неправителствени организации за успешната
              реализация на Културни, Просветни, <br />
              Образователни и Научни проекти: в страната и българските
              институции зад Държавна-та ни граница!
              <br />
              <br />
              2.2. Финансиране: дейността на Фондацията се финансира на
              се-гашния етап: от лични средства от членовете на Фамилия Брацови!
              <br />
              <br />
              II ПРОЕКТ: <br />
              <br />
              Реализиран, продължава, и оптимизира позитивно развитие
              <br />
              <br />
              1. Списание „Птици в нощта‘
              <br />
              <br />
              1.1. Печатен орган на:
              <br />
              • Фондация „КОСМОС – Димитър Брацов“ – Стара Загора
              <br />
              • Дружество на писателите – Стара Загора
              <br />
              • Съюз на художниците – Представителство – Стара Загора
              <br />
              • НЧ „Христо Танев - 2020“ – Стара Загора
              <br />
              • Народните читалища на Община Стара Загора
              <br />
              • Народните читалища на Област Стара Загора
              <br />
              Списанието се издава регулярно всяко тримесечие на:
              <br /> 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021 и 2022
              година
              <br /> и ще продължава да се издава през идните години до тогава,
              <br /> докато бъде полезно и интерес-но за хилядите свои
              читатели!!!
              <br />
              <br />
              1.2. Считано от март 2014 година, Фондация „Космос – ДБ“ е
              собст-веник и издател на списание „Птици в нощта“!
              <br />
              Ювелирно списание за: Поезия, Проза, Критика, Публицистика,
              Об-разование, Наука, Духовност, Бизнес + Лидерство = Просперитет!
              <br />
              <br />
              1.3. Видно от издадените досега екземпляри, списанието се списва
              от редколегия, в списъка на която личат знаковите имена на
              най-изявените творци на Стара Загора, региона и страната, - Поети,
              Писа-тели, Художници, Читалищни дейци, Професори, Преподаватели от
              Тракийски университет – Стара Загора!
              <br />
              <br />
              1.4. Списание „Птици в нощта“ се радва на покровителството на :
              Община Стара Загора, на Т.П.Палата – Стара Загора, на
              Ръководството на Тракийски университет – Стара Загора и на
              Благословията на Бъл-гарската Православна църква – Старозагорска
              епархия и на негово Ви-сокопреосвещенство – Митрополит Киприан!
              <br />
              <br />
              2. Финансиране:
              <br />
              <br />
              2.1. Годишни абонаменти от юридически и физически лица
              <br />
              2.2. Продажби на: книги и екземпляри от списанието
              <br />
              2.3. От лични средства на членовете на фамилия Брацови
              <br />
              <br />
              III ПРОЕКТ:
              <br />
              <br />
              Реализиран, продължава и оптимизира позитивно развитие
              <br />
              <br />
              1. Месец Май 2014 година – Първи по рода си Национален
              <br />
              и с международно участие Конкурс за поезия в памет <br />
              на Академик Николай Лилиев - с участието на творци <br />
              от Стара Загора, Република България и от чужбина!
              <br />
              <br />
              2. Този престижен конкурс се организира, оптимизира и провежда
              всяка следваща година: 2015, 2016, 2017, 2018, 2019, 2020, 2021 и
              ще продължава през 2022 година – Девето издание!, и през бъдните
              го-дини, докато е потребен на поетите-творци.
              <br />
              <br />
              3. Финансиране:
              <br />
              <br />
              3.1. Лични средства на членовете на Фамилия Брацови
              <br />
              3.2. Съучастие на Община Стара Загора
              <br />
              3.3. Участие на Фондация „Космос – ДБ“ – Стара Загора
              <br />
              3.4. Съпричастие от НЧ „Николай Лилиев-2005“-Стара Загора
              <br />
              3.5. Съпричастие от Дружество на писателите – Стара Загора
              <br />
              3.6. Съпричастие от НЧ „Даскал Петър Иванов-1988“ – Ст. Загора
              <br />
              3.7. Съпричастие от НЧ „Христо Танев -2020“ – Стара Загора
              <br />
              <br />
              IV ПРОЕКТ:
              <br />
              <br />
              Реализиран и продължава и оптимизира позитивно развитие
              <br />
              <br />
              Дружество на писателите – Стара Загора
              <br />
              <br />
              1. Учредяване – с активната организаторска и финансова подкрепа на
              Фондацията. В края на 2017 година беше учредено (възстановено)
              Дружеството на писателите – гр. Стара Загора, като
              Представителство на съюза на писателите в Република България с
              ръководство:
              <br />
              Председател: Таньо Клисуров – член на СБП
              <br />
              Членове: Виолета Бончева – член на СБП
              <br />
              Йордан Пеев – член на СБП
              <br />
              <br />
              2. Регистрация на Дружеството:
              <br />
              Януари 2018 г. – Търговки регистър – Стара Загора
              <br />
              С финансовата подкрепа на Фондация „Космос – ДБ“ – учреденото
              (възстановеното) Дружество на писателите беше регистрирано и
              леги-тимирано в Търговския регистър на Стара Загора като
              юридическа структура „сдружение“ с дейност в обществена полза, с
              банкова смет-ка, с нормативни разходно-оправдателни фактури и пр.
              <br />
              Юридическа структура, легитимна да бъде партньор на държавни,
              обществени и общински организации в успешната реализация на
              културни, образователни и научни проекти.
              <br />
              <br />
              3. Финансиране
              <br />
              Като „сдружение“ с културна дейност в обществена полза,
              Дружес-твото на писателите – Стара Загора има Законовата форма да
              ползва субсидиране за своята дейност от Общината и прочие: <br />
              <br />
              Източници на финансиране:
              <br />
              3.1. От членски внос на членовете на Дружеството.
              <br />
              3.2. От Фондация „Космос – ДБ“ – Стара Загора
              <br />
              <br />
              V ПРОЕКТ:
              <br />
              <br />
              Реализиран и продължава и оптимизира позитивно развитие
              <br />
              <br />
              Народно читалище „Христо Танев – 2020“ Стара Загора
              <br />
              <br />
              1. Статут на Фондация „Космос – Дим.Брацов“ Стара Загора:
              <br />
              • Съучредител на НЧ „Христо Танев-2020“ <br />
              • Корпоративен член на Читалището
              <br />
              • Президент – Димитър Брацов – Председател на Провери-телната
              комисия на Читалището
              <br />
              <br />
              2. Финансиране
              <br />
              <br />
              2.1. Членски внос от членовете на Читалището
              <br />
              2.2. По регламент по Закона за народните читалища от
              Министерст-вото на Културата / Съюз на нар. читалища от Община
              Стара Загора
              <br />
              <br />
              VI ПРОЕКТ – в идейна фаза:
              <br />
              Клуб на културните дейци – гр. Стара Загора
              <br />
              1. Учреден – Дата - 01.06.2022 година
              <br />
              В рамките на дейността на Фондация „Космос ДБ“ – Стара Загора
              <br />
              Местонахождение – гр. Стара Загора, кв. 209
              <br />
              Административна регистрация: ул. „Сердика“ N 22 <br />
              Бизнес център на ЕТ „БИМЕД – Ив. Брацова“, ет. III, <br />
              Конферентна зала, предоставена от фамилия Брацови.
              <br />
              2. Клуб на Културните дейци - <br />
              Цел: Възстановяване емблематичния Клуб на културните дейци на гр.
              Стара Загора от втората половина на миналия век. <br />
              Нанасяне: След Акт, Обр. 16, на Бизнес-центъра, <br />
              Срок: 01.11.2022 г. – Ден на народните будители
              <br />
              3. Статут, цели и задачи: <br />
              учреден, организация, оптимизация и позитивно развитие. <br />
              В определения срок ще бъде предоставен от: ЕТ „БИМЕД – Ив.
              Бра-цова“ – Стара Загора и от Фамилия Брацови за Творчески Дом на:
              <br />
              3.1. - Фондация „Космос – Дим. Брацов“ – Стара Загора – офис на
              Фондацията
              <br />
              3.2. Офис на списание „Птици в нощта‘
              <br />
              3.3. Книжарница: За списания, книги, литература, публицистика,
              журналистика, произведения на изобразителното изкуство (живопис,
              хералдика, пластика и др.)
              <br />
              4. Дружеството на писателите – Стара Загора
              <br />
              5. Съюз на художниците – Представителство – Стара Загора
              <br />
              6. Съюз на журналистите – Стара Загора
              <br />
              7. Интелектуалният елит на Стара Загора
              <br />
              8. Конферентна зала – около 80 кв. м площ
              <br />
              8.1. Мултимедия, видео
              <br />
              8.2. Бар плот
              <br />
              8.3. Щандове и стелажи по стените за: портрет + творческа
              би-ография + образци от произведенията на Именити творци: Поети,
              Пи-сатели, Белетристи, Публицисти, Журналисти, Художници (Рицарите
              на палитрата и Четката)
              <br />
              9. Финансиране:
              <br />
              9.1. Лични средства на членовете на Фамилия Брацови – Ст.Загора
              <br />
              9.2. Фондация „Космос – Дим. Брацов“ - Стара Загора
              <br />
              9.3. ЕТ „БИМЕД – Ив. Брацова“ – Стара Загора
              <br />
              9.4. Почитатели, приятели, - с лични вноски: съпричастни на
              Об-ществено-полезната Апостолска дейност на Фондацията
              <br />
              <br />
              VII ПРОЕКТ: <br />
              <br />
              „ЗЛАТЕН ФОНД“
              <br />
              „Да съхраним интелектуалното богатство <br />
              на нашето, Великото време <br />
              и дар да го дарим <br />
              на поколенията след нас!“
              <br />
              Обосновка: Интелектуалният елит на Стара Загора
              <br />
              Доброволно обединен във:
              <br />
              • Фондация „Космос – Димитър Брацов“ – Стара Загора
              <br />
              • Дружество на писателите – Стара Загора
              <br />
              • Съюз на художниците – Представителство – Стара Загора
              <br />
              • Народните читалища – Стара Загора
              <br />
              • Редколегията на нашия общ печатен орган – Списание „Птици в
              нощта“.
              <br />
              <br />
              В процесите на нашата интелектуална обществено-полезна Апос-толска
              дейност, стигнахме до извода:
              <br />
              Генетичните възможности на творците в Стара Загора, предвид
              напредналата възраст на много от тях, са на изчерпване!
              <br />
              Натрупаният Интелектуален потенциал у всеки един от тях трябва да
              бъде споделен предаден на младите, на следващите поколения!
              <br />
              Породи се идеята, която искаме да споделим и която се надяваме, че
              ще бъде приета не само като полезна, но и перспективна
              необходи-мост!
              <br />
              <br />
              Приложение - ПРОЕКТ 2022 година
              <br />
            </span>
          </ReactReadLessMore>
        </div>
        <br />
        <div className="border">
          <ReactReadLessMore
            lessText="Скрий"
            moreText="Покажи"
            displayCount={2}
          >
            <span>
              <h1>Фондация „КОСМОС – Димитър Брацов“ Стара Загора</h1>
            </span>
            <span>
              Кадрови състав (меценати + съучредители + почетни членове) :<br />
              <br />{" "}
            </span>
            <span>
              I. Меценати: Династия / Фамилия Брацови – Стара Загора
              <br />
              <br />
              1. Димитър Генов Брацов – 0898 679609 – председател
              <br />
              <br />
              2. Иванка Митева Брацова – 0898 650609 – Бизнес лидер, Президент /
              Собстве-ник на ЕТ „БИМЕД- Иванка Брацова“ – Ст. Загора Основен
              спонсор на Фондацията
              <br />
              <br />
              3. инж. Милена Димитрова Брацова – 0898 546530 <br />
              Бизнес лидер, Директор „Живото-застраховане“ – ДЗИ – гр.София
              <br />
              Представител на Фондацията за столицата София, страната, близка и
              да-лечна чужбина
              <br />
              Отговорен редактор на рубриката „Бизнес, лидерство, <br />
              просперитет“ в списание „Птици в нощта“
              <br />
              <br />
              4. Георги Николаев Барбудов – тел. <br />
              САЩ, щат Илиноис, гр. Чикаго, Бизнесмен, <br />
              Представител на Фондацията и списание „Птици в нощта“ <br />
              пред българските организации и институции в Чикаго
              <br />
              <br />
              5. Евгений Димитров Брацов – 0898 415068
              <br />
              Бизнес лидер, в ЕТ „Бимед – Ив. Брацова“, Стара Загора
              <br />
              Редактор в списание „Птици в нощта“, рубрика „Бизнес, <br />
              лидерство, просперитет“
              <br />
              <br />
              6. Мария Мирославова Брацова – 0898 579231 <br />
              ЕТ „Бимед – Ив. Брацова“ Ст. Загора, Отдел „Чов. ресурси
              <br />
              Фондация „Космос – Д. Брацов“, Ст. Загора – Бизнес-контакти
              <br />
              <br />
              7. Димитър Евгениев Брацов – 0895 441869
              <br />
              7.1. Софийски държавен университет- студент IV курс
              <br />
              7.2. ЕТ „Бимед Ив.Брацова“ Ст. Загора: наследник II генерация
              <br />
              7.3. Фондация „Космос – ДБ“ Ст.Загора: наследник II генерация
              <br />
              Печатен орган „Птици в нощта“ – пласмент и реклама
              <br />
              Рубрика „Бизнес, лидерство, просперитет“ - редактор
              <br />
              <br />
              8. Ивайло Евгениев Брацов – 0894 381820
              <br />
              Завършил VII клас 2022 г. с пълно отличие
              <br />
              Приет – ПГСАГ - Стара Загора – специалност „Архитектура“
              <br />
              Наследник II генерация: ЕТ „Бимед – Ив.Брацова“ Ст.Загора
              <br />
              Наследник II генерация: Фондация „Космос – ДБ“ Ст. Загора
              <br />
              <br />
              9. Красимир Георгиев Брацов – племенник (от рода Брацови)
              <br />
              АЙ ТИ специалист: ЕТ „Бимед – Ив.Брацова“ Ст.Загора
              <br />
              <br />
              II. Съучредители:
              <br />
              <br />
              1. Община Стара Загора
              <br />
              1.1. г-жа Иванка Сотирова – зам. кмет
              <br />
              <br />
              2. Тракийски университет – Стара Загора
              <br />
              2.1. проф. д.в.м.н. Иван Въшин <br />
              <br />
              3. Арт галерия „Алба Авитохол“ Ст.Загора
              <br />
              3.1. Христо Николов Танев – художник хералдик
              <br />
              3.2. Кънчо Николов - художник
              <br />
              3.3. Васа Русева – уредник на галерията
              <br />
              3.4. Валентин Дончевски – художник импресионист
              <br />
              <br />
              4. Български културно-информационен център гр. Братислава
              <br />
              4.1. г-жа Ваня Радева – Директор
              <br />
              <br />
              5. Стойчо Митев Стойчев (Маджарски) – Ст. Загора, поет, писател
              <br />
              Литературен псевдоним – Стойчо Маджарски
              <br />
              Учредител на Фондацията
              <br />
              Почетен член на Фондацията
              <br />
              Основател на Списание „Птици в нощта“
              <br />
              Почетен абонат на списание „Птици в нощта“
              <br />
              <br />
              6. инж. Иван Рашовски – поет, писател, публицист
              <br />
              Бизнесмен, собственик на „Колор пласт“ – Стара Загора
              <br />
              Почетен член на Фондацията <br />
              Съосновател на списание „Птици в нощта“
              <br />
              Редактор рубрика „Бизнес, лидерство, просперитет“
              <br />
              Абонат на списание „Птици в нощта“
              <br />
              <br />
              7. Народно читалище „Даскал Петър Иванов -1988“ – Стара Загора
              <br />
              7.1. Йордан Атанасов - поет, писател, публицист
              <br />
              Председател на Читалището
              <br />
              Член на Съюза на българските писатели
              <br />
              Гл. редактор на в. „Литературен глас“
              <br />
              Редактор в сп. „Птици в нощта“
              <br />
              Учредител на Фондацията
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              7.2. Красимира Божанова Бобева – програмист, поет
              <br />
              Литературен псевдоним - Кристина Божанова
              <br />
              Почетен член на Фондацията
              <br />
              Редактор и коректор на в. „Литературен глас“
              <br />
              Редактор и коректор в списание „Птици в нощта“
              <br />
              <br />
              8. Народно читалище „Николай Лилиев – 2005“ Ст. Загора
              <br />
              Акад. Крум Георгиев – председател на читалището
              <br />
              Поет, писател, публицист
              <br />
              Почетен гражданин на Стара Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“ Ст. Загора
              <br />
              Редактор в списание „Птици в нощта“
              <br />
              <br />
              9. Фондация „Пламък“ Ст. Загора
              <br />
              Трифон Митев – Председател
              <br />
              Поет, публицист, писател
              <br />
              Учредител на Фондация „Космос – ДБ“ – Ст. Загора
              <br />
              Почетен член на Фондацията
              <br />
              Редактор в сп. „Птици в нощта“
              <br />
              <br />
              10. Николай Марков Василев – адвокат
              <br />
              Учредител. Регистратор, правен защитник
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              <br />
              11. Д-р Иван Аяров – поет, белетрист, публицист
              <br />
              Заслужил деятел на Читалищното дело
              <br />
              Председател на НЧ „Св. Климент Охридски“
              <br />
              Съучредител и почетен член на Фондацията
              <br />
              <br />
              III. Интелектуален, обществено значим и духовен елит, <br />
              съпричастен на нашето обществено полезно Апостолско дело
              <br />
              <br />
              1. Българска православна църква, Старозагорска епархия
              <br />
              Негово Високопреосвещенство Митрополит Киприан – <br />
              Покровител на нашето обществено полезно Апостолско дело
              <br />
              <br />
              2. Живко Веселинов Тодоров <br />
              Кмет на Община Стара Загора
              <br />
              Съпричастен към нашето благородно Апостолско дело
              <br />
              <br />
              3. Таньо Стоянов Клисуров <br />
              Поет, белетрист, публицист, преводач
              <br />
              Почетен гражданин на Стара Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Член на Съюза на българските писатели
              <br />
              Председател на Дружеството на писателите в Стара Загора
              <br />
              Главен редактор на списание „Птици в нощта“
              <br />
              <br />
              4. Виолета Бончева – поет, писател, публицист
              <br />
              Член на Съюза на българските писатели
              <br />
              Член на Ръководството на Дружеството на писателите Ст. Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Редактор и ПИАР на сп. „Птици в нощта“
              <br />
              <br />
              5. Йордан Димитров Пеев – поет, писател, драматург
              <br />
              Член на Ръководството на Съюза на българските писатели
              <br />
              Член на ръководството на Дружеството на писателите Ст. Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Редактор в сп. „Птици в нощта“
              <br />
              <br />
              6. Йордан Илиев Стоев – поет, писател, публицист
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Зам. гл. редактор на сп. „Птици в нощта“
              <br />
              <br />
              7. Професор Марин Добрев
              <br />
              Директор Художествена галерия Стара Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Редактор и почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              8. Ботьо Буков – поет, писател, публицист, испанист
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Редактор на сп. „Птици в нощта“
              <br />
              <br />
              9. Доц. Злати Златев (посмъртно)
              <br />
              Художник, екс председател на Представителството
              <br />
              на Българските художници – Ст.Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Редактор на сп. „Птици в нощта“ – <br />
              рубрика „Изобразително изкуство“
              <br />
              <br />
              10. Милен Алагенски – художник
              <br />
              Председател на Представителството <br />
              на Българските художници – Ст.Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Редактор на сп. „Птици в нощта“ <br />
              <br />
              11. Димо Узунов – поет, писател, поклонник <br />
              в нашите манастири в Света гора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Редактор на сп. „Птици в нощта“ <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              12. инж. Олег Стоилов
              <br />
              Председател Търговско-промишлена палата Ст.Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Рубрика „Бизнес, лидерство, просперитет“ в сп. „Птици в нощта“ –
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              13. Петър Желязков – Община Стара Заора
              <br />
              Общински съветник – зам. председател на Комисията по култура
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              14. ОЗ генерал Тодор Димитров Георгиев
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              15. Денчо Недялков – Нотариална камара
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              16. Станка Желязкова Атанасова
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              17. Вестник „Наблюдател“ ЕООД - Стара Загора <br />
              Стефан Кацаров
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              18. инж. Ангел Николов Стоманярски – Директор
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              19. Огнян Христов Танев – бизнесмен
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              20. Доц. Петко Сребрев Петков – 0886665529
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              21. Пенчо Тенев Пенчев – поет, писател, публицист
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              22. Мина Иванова Маринова – поет, писател, творец
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              23. Чавдар Георгиев Димов – бизнесмен
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              24. инж. Кольо Димов – пенсионер
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              25. Николай Петков Дялков – поет, писател, публицист
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“ тел. 0896653403
              <br />
              <br />
              26. инж. Динко Николов Карафезиев – бизнесмен
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“ тел. 0887 317904
              <br />
              <br />
              27. инж. Димитър Тодоров Баев – тел. 0887 958645
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              28. О.Р. полк. Димо Демиев Цонев – тел. 0879 347388
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              29. Радка Петкова Баева – поет, писател, публицист
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              30. ПГСАГ „Лубор Байер“ Стара Загора
              <br />
              Директор: инж. Георги Славов, тел. 0884 668455
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              31. НЧ „Христо Танев - 2020“ - Ст.Загора
              <br />
              Корпоративен член на Фондация „Космос ДБ“ Ст. Загора
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              32. прокурор Митко Игнатов
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              33. проф. Иван Върляков – Общински съвет, зам. председател
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              34. проф. Румен Отузбиров – преподавател
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              35. Димитър Бишев – Счетоводна къща, тел 0893 376722
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат (почетен) на сп. „Птици в нощта“
              <br />
              <br />
              36. отец Йордан Карагеоргиев тел. 0888 444768
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              37. Манол Манолов – Директор Токуда банк - Ст.Загора
              <br />
              Тел. 0884 933551, Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              38. Петър Драгиев – философ, журналист, поет, преводач
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              39. арх. Цвета Търпоманова – проектант - тел. 0898 629988
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              40. инспектор Георги Колев – МВР – Ст.Загора
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              41. Регионален Исторически музей гр. Стара Загора
              <br />
              Директор: Петър Калчев -тел. 0884 477136
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              42. Веско Колибаров – ДАНС – Ст.Загора 0885 074976
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              43. Неделчо Ганев – поет, писател, бизнесмен
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              44. Николай Георгиев Дойнов, тел. 08997 989758
              <br />
              Председател на Съюз на Народните читалища – гр. София
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              45. д-р Тошко Толев – психиатър – тел. 0888 751105
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Автор и Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              46. Събчо Минков Събев – тел. 0886 435720
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              47. Господин Петров Велев – тел. 0886 629501
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              48. НЧ „Светлина“ - гр. Хасково
              <br />
              Секретар: Донка Кожухарова
              <br />
              Тодор Кожухаров – 0879 858329
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              49. Стефан Гео Милев – тел. 0884 990125
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Почетен абонат на сп. „Птици в нощта“
              <br />
              <br />
              50. Народно читалище с. Златна ливада, община Чирпан
              <br />
              Почетен член на Фондация „Космос – ДБ“
              <br />
              Абонат на сп. „Птици в нощта“
              <br />
              <br />
              51. инж. Лидия Бакалова – публицист, тел. 0895 742065
              <br />
              <br />
              52. инж. Георги Господинов Икономов – тел. 0884 699875
              <br />
              <br />
              53. Акад. Васил Герлимов – НЧ „Св. Климент Охридски“ Ст.Загора
              <br />
              <br />
              Лауреати Първа награда от Националния Поетичен конкурс „Академик
              Нико-лай Лилиев“ от Второ (2015 г.) до Седмо издание (2020 г.):{" "}
              <br />
              (Заб. На Първо и на Осмо издание на конкурса Първа награда не е
              присъдена)
              <br />
              <br />
              54. Мариана Дафчева, гр. Асеновград, тел. 0896208866 (2015 г. )
              <br />
              <br />
              55. Людмил Лефтеров Симеонов гр. Свищов, тел. (2016 г.)
              <br />
              <br />
              56. Лили Славова Качова, гр. София (2017 г.)
              <br />
              <br />
              57. Нели Христова Коларова, гр. София, Тел.: 0887 896681 (2018 г.)
              <br />
              <br />
              58. Мима Маринова Иванова, Стара Загора, Тел. 0895 146 091 (2019
              г.)
              <br />
              <br />
              59. Димитър Христов Черняев, София, Телефон: 0877523449 (2020 г.)
              <br />
              <br />
              60. Девети поетичен конкурс – лауреат първа награда:
              <br />
              ………………………………………………………………………………….
              <br />
              61. Христо Христов – Национален бизнес портал – Ст. Загора
              <br />
              Рекапитулация:
              <br />
              I. Меценати 9<br />
              II. Съучредители 15
              <br />
              III. Почетни членове 61 <br />
              Всичко: 85
              <br />
              <br />
              Президент на Фондацията: Димитър Брацов
              <br />
            </span>
          </ReactReadLessMore>
        </div>
      </div>
    );
  }
}
