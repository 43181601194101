// eslint-disable-next-line
export default [
    {
        sys: {
            id: "1",

        },
        fields: {
            name: "paragraph one",
            text: `Още в първите десетилетия след Освобождението на България в Стара Загора се зараждат първите прояви на литературен живот. През 1910 година около гимназиста Атанас Илиев се формира първият литературен кръжок, който е израз на необходимостта от организационен литературен живот.
Между 1911 и 1926 година в Стара Загора излизат вече девет литературни списания, някои от които на добро художествено равнище: „Искрици“, „Трептящи акорди“, „Хризантеми“ и др. Изявяват се старозагорски творци, чиито имена ще се утвърждават в бъдеще и ще дават литературния облик на града – Иван Хаджихристов, Иван Мирчев, Богдан Баров и др. Път за тях са проправили имена като Димитър Подвързачов и Кирил Христов. А заедно със старозагорските си колеги принос за литературната слава на Стара Загора дават и талантливите пера на Николай Лилиев, Георги Бакалов, а по-късно и Веселин Ханчев. Това дава право на Кирил Христов да заяви: „Без Стара Загора няма българска литература“. А родният град на толкова поетични таланти основателно си спечелва името „Градът на поетите“.
`
        }
    }, {
        sys: {
            id: "2",

        },
        fields: {
            name: "paragraph two",
            text: ` На 19 октомври 1944 година, веднага след обществените промени, е учреден литературният кръжок „Георги Бакалов“, продължител на Съюза на трудово-борческите писатели и неговия орган „Литературен подем“. А през 1964 година кръжокът се преобразува в Дружество на писателите, като формирование на Съюза на българските писатели – първото в страната. В средата на 70-те години като орган на писателското дружество започва да излиза литературният алманах „Хоризонт“, а литературната притурка на вестник „Септември“ - „Съвремие“ е ежемесечна трибуна на старозагорските литературни творци. Активни сътрудници на изданията са Константин Колев, Антон Михайлов, Стойчо Стойчев, Христо Кацаров, Жеко Христов, Янко Димов, Стоян Стаев, Петър Тонков, Йордан Стоев, Димитър Данаилов, Ганчо Проданов, Кънчо Стоянов,  Иван Груев, Таньо Клисуров, Неделчо Ганев, а ги илюстрират художниците Иван Попчев, Михаил Косев, Христо Танев, Тодор Ковачев и др.
През 1980 година старозагорското писателско дружество вече има шест редовни членове на Съюза на българските писатели, което го прави най-авторитетното поделение на писателския съюз. Негов председател е Иван Мирчев. Ежегодно излизат книги на старозагорски автори в най-авторитетните български издателства: „Български писател“, „Народна младеж“, „Христо Г.Данов“, „Георги Бакалов“. Старозагорци са отличавани с награди на Съюза на писателите, на различни литературни празници в страната. В Стара Загора се провеждат Есенни литературни дни, когато се връчва и националната награда за гражданска поезия „Гео Милев“. С нея са отличавани поети като Веселин Андреев, Михаил Берберов, Рашко Стойков, Стойчо Стойчев и др. Ежегодно се присъжда и наградата „Стара Загора“ - първа и втора степен.
`
        }
    },
    {
        sys: {
            id: "3",

        },
        fields: {
            name: "paragraph three",
            text: ` След промените през 1989-та година литературният живот в града организационно търпи промени. Създават се две дружества. Книги се издават, но проявите на писателите са разпилени, изявите им не се популяризират достатъчно. Дружеството губи базата си „Дом „Литературна Стара Загора“…
В последните години, макар и неорганизиран достатъчно, литературният живот не е замирал. Книги се издават дори повече от преди, но естетически повечето не издържат високия критерий, който бе прилаган някога. И все пак, книгите на Иван Груев, Георги Янев, Йордан Стоев, Таньо Клисуров, Неделчо Ганев, Мария Донева, Йордан Пеев, Виолета Бончева, Керка Хубенова, Ботьо Буков, Йордан Атанасов, Стойчо Маджарски, Стефанка Мирчева, Борислава Бонева, Кристина Божанова привличат вниманието и на читателите, и на критиката. Много важно е, че не прекъснахме връзките си с читателите. Освен редовните премиери на нови книги в библиотека "Захарий Княжески", Библиотека "Родина", Къща-музей "Гео Милев", много често ние сме и в селата от общината. Там хората ни посрещат много сърдечно, доказват с вниманието си и аплодисментите, с които ни даряват, че имат нужда от срещите с днешните писатели. Така беше в Оряховица, в Калояновец и Християново, на Старозагорските минерални бани,  в Казанка и другаде.
`
        }
    },
    {
        sys: {
            id: "4",

        },
        fields: {
            name: "paragraph four",
            text: ` През октомври 2017 година се проведе учредително събрание на старозагорското писателско дружество, с което неговата организационно-творческа дейност бе възстановена. През януари следващата година дружеството бе регистрирано в съда и неговата дейност стана легитимна. Старозагорските писатели имат своя трибуна – списание „Птици в нощта“, което излиза четири пъти в годината. `
        }
    },
    {
        sys: {
            id: "5",

        },
        fields: {
            name: "paragraph five",
            text: ` Избрано бе ръководство в състав: Таньо КЛИСУРОВ, председател, Виолета БОНЧЕВА и Йордан ПЕЕВ, членове.`
        }
    },
    {
        sys: {
            id: "6",

        },
        fields: {
            name: "paragraph six",
            text: ` Съвместно с Община Стара Загора, Фондация „Космос“, читалище „Николай Лилиев“, списание „Птици в нощта“, вестник „Литературен глас“ и дружеството на писателите провеждаме ежегодно през месец май национален конкурс за поезия „Николай Лилиев“.  `
        }
    },

]