import React, { Component } from 'react';



export default class Books extends Component {
    render() {
        return (

            <section className="container">
                <h1> Списанието "Птици в нощта"</h1>
                <h1>Очаквайте скоро и тук списанията "Птици в нощта"</h1>
                <p>Това списание е за проза, поезия и публицистика и позоволява на автори от Стара Загора и региона да споделят своите произведения </p>
                <p> Със доброволното съдействие на "интелектуален клуб "Космос" с председател Димитър Брацов</p>
            </section>
        )
    }
}
