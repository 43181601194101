// eslint-disable-next-line
export default [
    {
        sys: {
            id: "1",
            visible: true
        },
        fields: {
            name: "paragraph one",
            text: `В резиденция “Змейово” на бизнес фамилията Брацови на 1 юни 2013 г. бе учреден клуб “Космос”
                    за литература, изкуство, култура с представители на различни творчески организации. Димитър Брацов,
                    глава на семейството, декларира намерението на неговите членове до последните наследници да бъдат спонсори,
                    покровители на това, което се открива с клуба “Космос”.
                    Срещата започна с есе-откровение за Майка България
                    което поднесе домакинът и вдъхновител за създаването на творческия дом.`
        }
    }, {
        sys: {
            id: "2",
            visible: true
        },
        fields: {
            name: "paragraph two",
            text: ` Високопатриотичното слово на Димитър Брацов трогна присъстващите. В него той сподели своето верую за българската мечта да бъдеш основна родова клетка в едно високоразвито българско общество с висок морал и интелектуален потенциал. В есето бе отредено място на рода, на спомените за него и пазителите на паметта за миналото, за дедите.
                    В историята на фамилията Брацови се пази спомена за техния праотец, който е бил войвода на чета по време на робството
                    Всичко, което го има България, е придобито в труд и в бран, отвоювано е с огън и меч от храбрите нейни чеда, подчерта авторът.`
        }
    },
    {
        sys: {
            id: "3",
            visible: false
        },
        fields: {
            name: "paragraph three",
            text: ` Достойна памет за родното място и за близките хора бе и разказът на майката Иванка Брацова, президент на фамилната фирма ЕТ “БИМЕД-ИБ”. Нейният род е от ямболското село Бояджик. В неговата история има неща, които потомците свято пазят, като опожаряването на селото и изкланите 120 души българи от турските башибозуци. Един от пострадалите, останал сирак, роден на 6 януари 1876 г., е
                    бащата на създателя на компютъра Джон Атанасов.
                    Безкрайни са неговите митарства в Америка, където едва 13-годишен е изоставен от своя вуйчо, търговец на розово масло. Сред многото му деца е и Джон Атанасов. Г-жа Брацова разказа за вълнуващите му срещи с неговите родственици в с. Бояджик.
`
        }
    },
    {
        sys: {
            id: "4",
            visible: false
        },
        fields: {
            name: "paragraph four",
            text: ` Като президент и собственик на фамилната фирма, тя подчерта факта, че “БИМЕД-ИБ” ще бъде в основата на бъдещата дейност на клуб “Космос”.
                    В този ден прозвучаха много послания за обич и благодарност към родителите. Цялото семейство, съпругът на г-жа Брацова и нейните деца – Милена и Евгений са завършили Строителния техникум (Професионална гимназия по строителство, архитектура и геодезия “Лубор Байер”) в Стара Загора. Родителите са работили по национални обекти. Снаха им Мария ги е зарадвала с двама внука – Димитър и Ивайло.
                `
        }
    },
    {
        sys: {
            id: "5",
            visible: false
        },
        fields: {
            name: "paragraph five",
            text: ` Моите родители, сподели синът Евгений, винаги са ме радвали с много неща. Баща ми ме изненада с това, което стана с него преди години.
                    Може би преди това таеше и не даваше гласност на скрития талант в него да пише и да разказва в рими. Но го разви. Изключително съм щастлив от този факт, тъй като винаги съм го считал, че е човек с възможности в съвсем друга сфера. Мисля, че не е моментът да говорим за историята на нашата фирма, за това, с което се занимаваме, което работим и от което се прехранваме. Именно тези 22 години бяха годините, в които работихме, и се надявам, че
                    създадохме много добро име в града.`
        }
    },
    {
        sys: {
            id: "6",
            visible: false
        },
        fields: {
            name: "paragraph six",
            text: ` Това, за което и в бъдеще ще продължим да работим, е именно за доброто име на тази фамилия, нещо, на което винаги съм държал, защото и моите родители са ме възпитавали по този начин. Надявам се и някой от нашите двама сина да доразвият в себе си тези скрити заложби, които дядо им показа, да станат хора на изкуството.
                    Пожелавам на клуб “Космос” да привлича все повече поддръжници все повече хора, които да се събират и да споделят своите мисли за живота и за света чрез словото и изкуството. Моят брат веднага се включи в осъществяване на идеята на нашия баща за създаването на този клуб, каза инж. Милена Брацова, директор на застрахователна агенция в София. Така начинанието на бащата е подкрепено от цялото семейство.
                `
        }
    },
    {
        sys: {
            id: "7",
            visible: false
        },
        fields: {
            name: "paragraph seven",
            text: ` Христо Танев, художник-хералдик и автор на логото на клуба, обясни знаците, които е използвал от глаголическата азбука на Св. Кирил Философ, знака на словото и този на кана, който е посветеният водач и който носи мъдростта на своя народ.
                    В резиденцията е отделен подходящ салон, в който в момента е подредена картинна колекция от творби на художника Кънчо Николов със сюжети от забележителната природа на с. Змейово.
                    На учредителната сбирка бе представен проектоуставът на клуба с пожеланието с идеите на неговите членове той да се утвърди като място, където творците ще имат свой дом, уют и възможности за творчески срещи и изяви.
                    Състоя се рецитал с участие на поетите Стойчо Маджарски, Йордан Атанасов и Димитър Брацов.

                    НБП, Донка ЙОТОВА`
        }
    }
    , {
        sys: {
            id: "8",
        },
        fields: {
            name: "paragraph eight",
            text: ` Димитър Брацов препуска със свещения дар на спомените`
        }
    }, {
        sys: {
            id: "9",
        },
        fields: {
            name: "paragraph nine",
            text: ` На 30 ноември 2013 г. в Регионална библиотека „Захарий Княжески“
                    бяха представени две книги с лирика и проза на Димитър Брацов –
                    „Завещание“ и „Легенди и митове“. Слово за тях произнесе Стойчо Маджарски.`
        }
    }, {
        sys: {
            id: "10",
        },
        fields: {
            name: "paragraph ten",
            text: ` Двете книги на Димитър Брацов са ясен знак за това, че българското поетично слово
                    няма така лесно да залезе и който го усеща в себе си, успява да му даде простор навън ведно
                    със своите художествени откривателства. Голяма част от поезията му носи характерната за
                    автора епичност на изказа. Брацов реди своя епос, в който главният герой на неговата лирика
                    прехвърля годините, сезоните, срещите, разделите – целия живот като в калейдоскоп с ярки багри.
                    Художественият рефлекс на този автор има много силен заряд, защото самият той е човек на земята
                    не само по произход, но и по призвание („Съдба“, „На тебе, моя любов“). Когато се връща назад във времето,
                    спомените изплуват в съзнанието на поета като свещени икони („Иконите на моите спомени“).`
        }
    },
    {
        sys: {
            id: "11",
        },
        fields: {
            name: "paragraph eleven",
            text: `    „По прашните дюлени пътища 
                    `
        }
    }, ,
    {
        sys: {
            id: "12",
        },
        fields: {
            name: "paragraph twelve",
            text: `    на босоногото детство
                    `
        }
    }, ,
    {
        sys: {
            id: "13",
        },
        fields: {
            name: "paragraph therteen",
            text: `   препускат пак спомени
                    `
        }
    }, ,
    {
        sys: {
            id: "14",
        },
        fields: {
            name: "paragraph fourteen",
            text: `  
                       събуждат ги слънчеви изгреви
                    `
        }
    }, ,
    {
        sys: {
            id: "15",
        },
        fields: {
            name: "paragraph fiftteen",
            text: ` 
                    приспиват ги вечерните залези…“
                    `
        }
    },
    {
        sys: {
            id: "16",
        },
        fields: {
            name: "paragraph sixteen",
            text: ` В поезията и в прозата на този автор спомените са свещен дар в дългия житейски път на човека.
                    Те събират в едно фамилните дни, най-близките хора – обична жена, деца и внуци, скъпи на сърцето вечни приятелства,
                    обвеяни от силата на най-топлите думи на преклонение и обич. Всичко е назовано с истинските имена, с точните слова,
                    които трябва да изразят безкрайната обич на автора към този сакрален за него свят на миналите, на сегашните
                    и на бъдещите дни на чудото, наречено живот.  `
        }
    },
    {
        sys: {
            id: "17",
        },
        fields: {
            name: "paragraph seventeen",
            text: ` Димитър Брацов остава верен на съпричастието си към радостта и към болката на човека. Но той не е някаква абстракция.
                    Това е живият човек до него, или този, който си е отишъл завинаги по „Млечния път през звездите“.

                    НБП, Донка ЙОТОВА`
        }
    }
]